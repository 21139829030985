import { CircularProgress } from "@mui/material";
import { Tooltip } from "components/common/Tooltip";
import React, { useEffect, useRef, useState } from "react";
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight } from "react-icons/fa";
import DraggableHeader from "./dnd";
import styles from "./styles.module.css";
import { TableColumn, TableProps, TableRow } from "./type";

const Table: React.FC<TableProps> = ({
    columns,
    data,
    pagination = false,
    paginationModel = undefined,
    filterable = false,
    serverMode = "client",
    loading = false,
    defaultPageSize = 10,
    tableHeight = "100%",
    defaultSelectedRows = undefined,
    selectableRows = false,
    nthChild = false,
    onRowClick = undefined,
    onRowSelect = undefined,
    onColumnOrderChange,
    onPaginationModelChange,
    onSortModelChange,
    onFilterModelChange,
}) => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageData, setCurrentPageData] = useState<TableRow[]>([]);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [sortKey, setSortKey] = useState<string | null>(null);
    const [sortOrder, setSortOrder] = useState<"asc" | "desc" | null>(null);
    const [sortModel, setSortModel] = useState<{ sortKey: string; sortOrder: "asc" | "desc" }[]>(
        []
    );
    const [filterModel, setFilterModel] = useState<
        { filterKey: string; filterValues: string[] | number[] }[]
    >([]);
    const [pageSize, setPageSize] = useState<number>(defaultPageSize);
    const [selectedRows, setSelectedRows] = useState<TableRow[]>([]);
    const [allSelected, setAllSelected] = useState<boolean>(false);
    const [currentColumns, setCurrentColumns] = useState<TableColumn[]>(columns);

    const selectAllRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setCurrentColumns(columns);
    }, [columns]);

    useEffect(() => {
        if (pagination) {
            if (serverMode === "server") {
                setCurrentPageData(data);
                if (paginationModel) {
                    setTotalItems(paginationModel.totalItems);
                    setTotalPages(paginationModel.totalPages);
                }
            } else {
                let sortedData = [...data];
                if (sortKey && sortOrder) {
                    sortedData.sort((a, b) => {
                        if (a[sortKey] < b[sortKey]) return sortOrder === "asc" ? -1 : 1;
                        if (a[sortKey] > b[sortKey]) return sortOrder === "asc" ? 1 : -1;
                        return 0;
                    });
                }
                setCurrentPageData(
                    sortedData.slice((currentPage - 1) * pageSize, currentPage * pageSize)
                );
                setTotalItems(data.length);
                setTotalPages(Math.ceil(data.length / pageSize));
            }
        } else {
            let sortedData = [...data];
            if (sortKey && sortOrder) {
                sortedData.sort((a, b) => {
                    if (a[sortKey] < b[sortKey]) return sortOrder === "asc" ? -1 : 1;
                    if (a[sortKey] > b[sortKey]) return sortOrder === "asc" ? 1 : -1;
                    return 0;
                });
            }
            setCurrentPageData(sortedData);
            setTotalItems(data.length);
            setTotalPages(1);
        }
    }, [currentPage, pageSize, sortKey, sortOrder, data, serverMode, pagination, paginationModel]);

    useEffect(() => {
        const allData = serverMode === "server" ? currentPageData : data;
        if (selectAllRef.current) {
            const currentPageSelectedRows = allData.map(row => row.id);
            const selectedCount = currentPageSelectedRows.filter(id =>
                selectedRows.some(rows => rows.id === id)
            ).length;
            if (selectedCount === 0) {
                selectAllRef.current.checked = false;
                selectAllRef.current.indeterminate = false;
                return;
            }
            if (selectedCount === allData.length) {
                selectAllRef.current.checked = true;
                selectAllRef.current.indeterminate = false;
            } else if (selectedCount > 0) {
                selectAllRef.current.checked = false;
                selectAllRef.current.indeterminate = true;
            } else {
                selectAllRef.current.checked = false;
                selectAllRef.current.indeterminate = false;
            }
        }
    }, [selectedRows, currentPageData, data, serverMode]);

    useEffect(() => {
        defaultSelectedRows && setSelectedRows(defaultSelectedRows);
    }, [defaultSelectedRows]);

    useEffect(() => {
        onRowSelect && onRowSelect(selectedRows);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows]);

    const handleSelectAll = () => {
        if (serverMode === "server") {
            if (allSelected) {
                setSelectedRows(prevSelectedRows =>
                    prevSelectedRows.filter(
                        prevRow => !currentPageData.some(row => row.id === prevRow.id)
                    )
                );
            } else {
                setSelectedRows(prevSelectedRows => [
                    ...prevSelectedRows,
                    ...currentPageData.filter(
                        row => !prevSelectedRows.some(prevRow => row.id === prevRow.id)
                    ),
                ]);
            }
        } else {
            if (allSelected) {
                setSelectedRows(prevSelectedRows =>
                    prevSelectedRows.filter(prevRow => !data.some(row => row.id === prevRow.id))
                );
            } else {
                setSelectedRows(prevSelectedRows => [
                    ...prevSelectedRows,
                    ...data.filter(row => !prevSelectedRows.some(prevRow => prevRow.id === row.id)),
                ]);
            }
        }
        setAllSelected(!allSelected);
    };

    const handleRowSelectChange = row => {
        setSelectedRows(prevState =>
            prevState.some(prevRow => prevRow.id === row.id)
                ? prevState.filter(prevRow => prevRow.id !== row.id)
                : [...prevState, row]
        );
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        onPaginationModelChange({ page, pageSize });
    };

    const handleMultiSort = sortModel => {
        onSortModelChange(sortModel);
    };

    const handleSort = (key: string) => {
        if (sortKey === key) {
            if (sortOrder === "asc") {
                onSortModelChange([{ sortKey: key, sortOrder: "desc" }]);
                setSortModel([{ sortKey: key, sortOrder: "desc" }]);
                setSortOrder("desc");
            } else {
                setSortOrder("asc");
                onSortModelChange([{ sortKey: key, sortOrder: "asc" }]);
                setSortModel([{ sortKey: key, sortOrder: "asc" }]);
            }
        } else {
            setSortKey(key);
            setSortOrder("asc");
            onSortModelChange([{ sortKey: key, sortOrder: "asc" }]);
            setSortModel([{ sortKey: key, sortOrder: "asc" }]);
        }
    };

    const handleMultiFilter = filterModel => {
        onFilterModelChange(filterModel);
    };

    const handlePageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPageSize(Number(event.target.value));
        onPaginationModelChange({ page: 1, pageSize: Number(event.target.value) });
        setCurrentPage(1);
    };

    const renderPaginationInfo = (): string => {
        const startItem = (currentPage - 1) * pageSize + 1;
        const endItem = Math.min(currentPage * pageSize, totalItems);
        return `Showing: ${startItem}-${endItem} of ${totalItems}`;
    };

    const renderPageNumbers = (): JSX.Element[] => {
        const pageNumbers: JSX.Element[] = [];
        const maxVisiblePages = 5;
        let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

        if (endPage - startPage + 1 < maxVisiblePages) {
            startPage = Math.max(1, endPage - maxVisiblePages + 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    className={`${styles.paginationButton} ${
                        currentPage === i ? styles.paginationActive : ""
                    }`}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </button>
            );
        }

        return pageNumbers;
    };

    const moveColumn = (dragIndex: number, hoverIndex: number) => {
        const updatedColumns = [...currentColumns];
        const [movedColumn] = updatedColumns.splice(dragIndex, 1);
        updatedColumns.splice(hoverIndex, 0, movedColumn);
        setCurrentColumns(updatedColumns);
        if (onColumnOrderChange) {
            onColumnOrderChange(updatedColumns);
        }
    };

    const isPaginationDisabled = totalPages <= 1 || currentPageData.length === 0;

    return (
        <>
            <div className={styles.tableContainer} style={{ height: tableHeight }}>
                {loading ? (
                    <CircularProgress color="inherit" size={24} className={styles.progress} />
                ) : (
                    <table className={styles.table}>
                        <thead>
                            <tr className={styles.tableHeaderRow}>
                                {selectableRows && (
                                    <th className={styles.tableHeaderCellSelect}>
                                        <input
                                            type="checkbox"
                                            ref={selectAllRef}
                                            onChange={handleSelectAll}
                                        />
                                    </th>
                                )}
                                {currentColumns.map((column, index) =>
                                    !column.hidden ? (
                                        <DraggableHeader
                                            key={column.key}
                                            column={column}
                                            allColumns={currentColumns}
                                            index={index}
                                            moveColumn={moveColumn}
                                            onSort={handleSort}
                                            onMultiSort={handleMultiSort}
                                            sortKey={sortKey}
                                            sortOrder={sortOrder}
                                            sortModel={sortModel}
                                            setSortModel={setSortModel}
                                            filterable={filterable}
                                            onMultiFilter={handleMultiFilter}
                                            filterModel={filterModel}
                                            setFilterModel={setFilterModel}
                                        />
                                    ) : null
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {currentPageData.length > 0 ? (
                                currentPageData.map((row, index) => (
                                    <tr
                                        key={index}
                                        className={styles.tableBodyRow}
                                        data-nth-child={nthChild}
                                        onClick={() => onRowClick && onRowClick(row)}
                                    >
                                        {selectableRows && (
                                            <td className={styles.tableBodyCellSelect}>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedRows.some(
                                                        selectedRow => selectedRow.id === row.id
                                                    )}
                                                    onChange={() => handleRowSelectChange(row)}
                                                />
                                            </td>
                                        )}
                                        {currentColumns.map(
                                            column =>
                                                !column.hidden && (
                                                    <td
                                                        key={column.key}
                                                        className={styles.tableBodyCell}
                                                        style={{
                                                            width: column.width,
                                                        }}
                                                    >
                                                        <Tooltip
                                                            title={
                                                                column.cell !== undefined
                                                                    ? column.cell(row)
                                                                    : row[column.key]
                                                            }
                                                        >
                                                            <span
                                                                className={styles.tableBodyCellSpan}
                                                            >
                                                                {column.cell !== undefined
                                                                    ? column.cell(row)
                                                                    : row[column.key]}
                                                            </span>
                                                        </Tooltip>
                                                    </td>
                                                )
                                        )}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td
                                        colSpan={currentColumns.length + (selectableRows ? 1 : 0)}
                                        style={{ textAlign: "center" }}
                                    >
                                        Data not found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                )}
            </div>
            {pagination && (
                <div className={styles.paginationContainer}>
                    <span className={styles.paginationSpan}>{renderPaginationInfo()}</span>
                    <select
                        className={styles.pageSizeSelect}
                        value={pageSize}
                        onChange={handlePageSizeChange}
                    >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                    <button
                        className={`${styles.paginationButton} ${
                            currentPage === 1 || isPaginationDisabled
                                ? styles.paginationButtonDisabled
                                : ""
                        }`}
                        onClick={() => handlePageChange(1)}
                        disabled={currentPage === 1 || isPaginationDisabled}
                    >
                        <FaAngleDoubleLeft size={18} />
                    </button>
                    <button
                        className={`${styles.paginationButton} ${
                            currentPage === 1 || isPaginationDisabled
                                ? styles.paginationButtonDisabled
                                : ""
                        }`}
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1 || isPaginationDisabled}
                    >
                        <FaAngleLeft size={18} />
                    </button>
                    {renderPageNumbers()}
                    <button
                        className={`${styles.paginationButton} ${
                            currentPage === totalPages || isPaginationDisabled
                                ? styles.paginationButtonDisabled
                                : ""
                        }`}
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages || isPaginationDisabled}
                    >
                        <FaAngleRight size={18} />
                    </button>
                    <button
                        className={`${styles.paginationButton} ${
                            currentPage === totalPages || isPaginationDisabled
                                ? styles.paginationButtonDisabled
                                : ""
                        }`}
                        onClick={() => handlePageChange(totalPages)}
                        disabled={currentPage === totalPages || isPaginationDisabled}
                    >
                        <FaAngleDoubleRight size={18} />
                    </button>
                </div>
            )}
        </>
    );
};

export default Table;
