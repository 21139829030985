/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";
import styles from "./BookingTrends.module.css";
import settingIcon from "../../../assets/icons/element-button-minimize-copy-2@3x2.webp";
import { BookingTrendsTableClient } from "lib/api/msd/bookingTrends/bookingTrendsTable";
import { BookingTrendsGraphClient } from "lib/api/msd/bookingTrends/bookingTrendsGraph";
import { FilterContext } from "context-api/FilterContext";
import { RangeSliderClient } from "lib/api/rangeSlider/RangeSlider";
import { BookingTrendsHolidaysClient } from "lib/api/msd/bookingTrendsHolidaysCountry";
import images from "constans/images";
import GetCarriers from "components/common/GetCarriers/GetCarriers";
import Card from "components/common/Card/Card";
import Carriers from "components/common/Carriers/Carriers";
import RangeSlider from "components/common/RangeSlider/RangeSlider";
import Plotly from "components/common/Charts/Plotly";
import Dropdown from "components/common/Dropdown/Dropdown";
import { ThemeContext } from "context-api/ThemeContext";
import { useOutsideClick } from "hooks/useOutsideClick";

import Table from "components/common/Table/newTable";
import { TableRow } from "components/common/Table/newTable/type";
import Skeleton from "components/common/Skeleton";

interface IBookingTrends {
  filterIsOpen: boolean;
}
interface IRangeSlider {
  value: number[];
  min: number;
  max: number;
  dateList: string[];
}
export default function BookingTrends({ filterIsOpen }: IBookingTrends) {
  const [chartData, setChartData] = useState({ data: [], layout: {} });
  const [data, setData] = useState<any>({
    data: [],
    columns: [],
  });
  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: 10,
  });
  const [sortModel, setSortModel] = useState<
    { sortKey: string; sortOrder: "asc" | "desc" }[]
  >([]);
  const [meta, setMeta] = useState<{
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  }>({
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
    totalPages: 1,
  });
  const [loading, setLoading] = useState(false);
  const [carriersJson, setCarriersJson] = useState<any>();
  const [selectedCarriers, setSelectedCarriers] = useState<string[]>([]);
  const [rangeValue, setRangeValue] = useState<IRangeSlider>({
    value: [],
    min: 0,
    max: 20,
    dateList: [],
  });

  const [holidaysCountryOptions, setHolidaysCountryOptions] = useState<
    string[]
  >([]);
  const [selectedHolidaysCountry, setSelectedHolidaysCountry] = useState<
    string[]
  >([]);
  const [isSelectedHolidaysCountry, setIsSelectedHolidaysCountry] =
    useState<boolean>(false);

  const [selectedHolidays, setSelectedHolidays] = useState<number[] | string[]>(
    []
  );
  const [defaultSelectedHolidays, setDefaultSelectedHolidays] = useState<
    TableRow[]
  >([]);
  const [trendsAndDetail, setTrendsAndDetail] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [viewIsActiveHistorical, setViewIsActiveHistorical] = useState(true);
  const [viewIsActiveForward, setViewIsActiveForward] = useState(true);
  const [monthlyDaily, setMonthlyDaily] = useState(false);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [carrierLoader, setCarrierLoader] = useState(false);
  const [rangeLoader, setRangeLoader] = useState(false);

  const { filterList } = useContext(FilterContext);
  const { theme } = useContext(ThemeContext);

  const wrapperRef: any = useRef(null);
  useOutsideClick(wrapperRef, () => setIsOpen(false));

  useEffect(() => {
    if (!filterIsOpen) {
      setCarrierLoader(true);
      BookingTrendsHolidaysClient.fetchBookingTrendsHolidays({
        filterList,
      })
        .then((response: any) => {
          setHolidaysCountryOptions(response.options);
          setSelectedHolidaysCountry(response.options ?? []);
          setIsSelectedHolidaysCountry(true);
          setCarriersJson(response?.carriers);
        })
        .catch(() => {
          console.log("Error");
        })
        .finally(() => {
          setTimeout(() => {
            setCarrierLoader(false);
          }, 2000);
        });
    }
  }, [filterIsOpen, filterList]);

  useEffect(() => {
    setRangeLoader(true);
    RangeSliderClient.fetchRangeSlider({
      agg_type: monthlyDaily ? "daily" : "monthly",
      time_direction:
        viewIsActiveForward && viewIsActiveHistorical
          ? "historical-forward"
          : viewIsActiveForward
          ? "forward"
          : viewIsActiveHistorical
          ? "historical"
          : "forward",
    })
      .then((response: any) => {
        setRangeValue((prevState) => ({
          ...prevState,
          max: response.values.length - 1,
          dateList: response.values,
          value: [response.start_idx, response.end_idx],
        }));
      })
      .catch(() => {
        console.log("Error");
      })
      .finally(() => {
        setTimeout(() => {
          setRangeLoader(false);
        }, 2000);
      });
  }, [monthlyDaily, viewIsActiveForward, viewIsActiveHistorical]);

  useEffect(() => {
    if (
      !filterIsOpen &&
      rangeValue.dateList.length > 0 &&
      isSelectedHolidaysCountry &&
      selectedHolidaysCountry.length > 0
    ) {
      const params = {
        graphCarriers: selectedCarriers.join(),
        time_direction:
          viewIsActiveForward && viewIsActiveHistorical
            ? "historical-forward"
            : viewIsActiveForward
            ? "forward"
            : viewIsActiveHistorical
            ? "historical"
            : "forward",
        date_range_start: rangeValue.dateList[rangeValue.value[0]],
        date_range_end: rangeValue.dateList[rangeValue.value[1]],
        agg_type: monthlyDaily ? "daily" : "monthly",
        holiday_countries: selectedHolidaysCountry.join(),
        show_holidays: "1",
        selected_holidays: "All",
        dark_theme: theme === "dark",
        page_: paginationModel.page,
        limit_: paginationModel.pageSize,
        ...(sortModel &&
          sortModel?.length > 0 && { sort_: JSON.stringify(sortModel) }), // _sort varsa ekle
      };
      setLoading(true);
      setSkeletonLoader(true);
      BookingTrendsTableClient.fetchBookingTrendsClient({
        filterList,
        params,
      })
        .then((response: any) => {
          setData({
            data: response?.data,
            columns: getColumns(response.labels),
          });
          setMeta(response?.meta);
          setDefaultSelectedHolidays(
            response?.data?.filter((item) => item?.is_selected)
          );
        })
        .catch(() => {
          setData({ data: [], columns: [] });
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        });
    }
  }, [
    filterIsOpen,
    filterList,
    isSelectedHolidaysCountry,
    paginationModel.page,
    paginationModel.pageSize,
    rangeValue.value,
    selectedCarriers,
    selectedHolidaysCountry,
    sortModel,
    theme,
  ]);

  useEffect(() => {
    if (
      !filterIsOpen &&
      rangeValue.dateList.length > 0 &&
      isSelectedHolidaysCountry &&
      selectedHolidays.length > 0
    ) {
      BookingTrendsGraphClient.fetchBookingTrendsClient({
        filterList,
        graphCarriers: selectedCarriers.join(),
        time_direction:
          viewIsActiveForward && viewIsActiveHistorical
            ? "historical-forward"
            : viewIsActiveForward
            ? "forward"
            : viewIsActiveHistorical
            ? "historical"
            : "forward",
        date_range_start: rangeValue.dateList[rangeValue.value[0]],
        date_range_end: rangeValue.dateList[rangeValue.value[1]],
        agg_type: monthlyDaily ? "daily" : "monthly",
        holiday_countries: selectedHolidaysCountry.join(),
        show_holidays: "1",
        selected_holidays: selectedHolidays.join(),
        // selectedHolidays.length > 0 ? selectedHolidays.join() : "All",
        dark_theme: theme === "dark",
      })
        .then((response: any) => {
          setChartData({
            data: response.fig.data,
            layout: response.fig.layout,
          });
        })
        .catch(() => {
          setChartData({ data: [], layout: {} });
          console.log("Error");
        })
        .finally(() => {
          setTimeout(() => {
            setSkeletonLoader(false);
          }, 2000);
        });
    }
  }, [selectedHolidays]);

  useEffect(() => {
    const x = [
      ...filterList.mainCompetitor,
      ...filterList.selectedCompetitors,
    ].find((item) => item === "All");
    const allParams = localStorage.getItem("allCompetitors");
    if (x === "All" && allParams) {
      setSelectedCarriers(JSON.parse(allParams));
    } else {
      setSelectedCarriers([
        ...filterList.mainCompetitor,
        ...filterList.selectedCompetitors,
      ]);
    }
  }, [filterList.mainCompetitor, filterList.selectedCompetitors]);

  const getColumns = (labels) => {
    const array: {
      title: string;
      key: string;
      cell: (row: any) => JSX.Element;
      width?: string | number;
      sortable?: boolean;
    }[] = [];
    labels.forEach((element) => {
      for (const [key, title] of Object.entries(element)) {
        const obj: any = {
          title,
          key,
          cell: (row) => {
            return <span className={"rdb-custom-cell"}>{row[key]}</span>;
          },
          width: 200,
          sortable: true,
        };
        array.push(obj);
      }
    });
    return array;
  };

  return (
    <Card variant="secondary" zIndex={6}>
      <div className={styles.card_header}>
        <span className={styles.card_title} data-theme={theme}>
          Booking Trends
          {/* <CardInfo
            title={storyText?.main_card?.title ?? null}
            description={storyText?.main_card?.content ?? null}
          /> */}
        </span>
        <div className={styles.trends_and_detail_button_wrapper}>
          <span className={styles.holidays_text} data-theme={theme}>
            Holidays:
          </span>
          <div className={styles.trends_and_detail_button} data-theme={theme}>
            <span
              className={styles.button_text}
              data-active={trendsAndDetail}
              onClick={() => setTrendsAndDetail(true)}
              data-theme={theme}
            >
              Trends
            </span>
            <span
              className={styles.button_text}
              data-active={!trendsAndDetail}
              onClick={() => setTrendsAndDetail(false)}
              data-theme={theme}
            >
              Detail
            </span>
          </div>
        </div>
      </div>

      <div style={{ position: "relative" }}>
        <div className={styles.trends_wrapper} data-active={trendsAndDetail}>
          <div className={styles.content}>
            <div className={styles.carriers_wrapper}>
              {carrierLoader ? (
                <Skeleton width={"100%"} height={48} />
              ) : (
                <Carriers
                  data={GetCarriers()}
                  added={selectedCarriers}
                  setAdded={setSelectedCarriers}
                  carriersJson={carriersJson}
                />
              )}
            </div>
            <div className={styles.open_menu_wrapper} ref={wrapperRef}>
              <img
                src={
                  theme === "dark" ? settingIcon : images.setting_button_light
                }
                alt=""
                className={styles.setting_icon}
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              />
              <div
                className={styles.open_menu}
                data-active={isOpen}
                data-theme={theme}
              >
                <div className={styles.open_menu_card} data-theme={theme}>
                  <div className={styles.open_menu_title_wrapper}>
                    <span className={styles.title_text} data-theme={theme}>
                      View
                    </span>
                    <img
                      src={images.eye}
                      alt=""
                      className={styles.open_menu_icons}
                      data-theme={theme}
                    />
                  </div>
                  <div className={styles.open_menu_buttons_wrapper}>
                    <span
                      className={styles.open_menu_buttons}
                      data-active={viewIsActiveForward}
                      onClick={() =>
                        setViewIsActiveForward(!viewIsActiveForward)
                      }
                      data-theme={theme}
                    >
                      Forward
                    </span>
                    <span
                      className={styles.open_menu_buttons}
                      data-active={viewIsActiveHistorical}
                      onClick={() =>
                        setViewIsActiveHistorical(!viewIsActiveHistorical)
                      }
                      data-theme={theme}
                    >
                      Historical
                    </span>
                  </div>
                </div>
                <div className={styles.open_menu_card} data-theme={theme}>
                  <div className={styles.open_menu_title_wrapper}>
                    <span className={styles.title_text} data-theme={theme}>
                      Time Range
                    </span>
                    <img
                      src={images.calendar}
                      alt=""
                      className={styles.open_menu_icons}
                      data-theme={theme}
                    />
                  </div>
                  <div className={styles.open_menu_buttons_wrapper}>
                    <span
                      className={styles.open_menu_buttons}
                      data-active={!monthlyDaily}
                      onClick={() => setMonthlyDaily(!monthlyDaily)}
                      data-theme={theme}
                    >
                      Monthly
                    </span>
                    <span
                      className={styles.open_menu_buttons}
                      data-active={monthlyDaily}
                      onClick={() => setMonthlyDaily(!monthlyDaily)}
                      data-theme={theme}
                    >
                      Daily
                    </span>
                  </div>
                </div>
                <div className={styles.open_menu_card} data-theme={theme}>
                  <div className={styles.open_menu_title_wrapper}>
                    <span className={styles.title_text} data-theme={theme}>
                      Holidays
                    </span>
                    <img
                      src={images.smileys}
                      alt=""
                      className={styles.open_menu_icons}
                      data-theme={theme}
                    />
                  </div>
                  <div>
                    <Dropdown
                      width={176}
                      data={holidaysCountryOptions}
                      added={selectedHolidaysCountry}
                      setAdded={setSelectedHolidaysCountry}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {skeletonLoader ? (
            <Skeleton width={"100%"} height={363} />
          ) : (
            <Card variant="primary" height={363}>
              <div className={styles.chart_wrapper}>
                <p className={styles.chart_title} data-theme={theme}>
                  # Passengers
                </p>
                <Plotly
                  data={chartData.data}
                  layout={chartData.layout}
                  height={254}
                  margin={{ t: 0, b: 20, l: 40, r: 0, p: 4 }}
                />
              </div>
            </Card>
          )}
          {rangeLoader ? (
            <Skeleton width={"100%"} height={46} />
          ) : (
            <Card variant="primary">
              <div className={styles.range_wrapper}>
                <RangeSlider
                  data={{
                    max: rangeValue.max,
                    min: rangeValue.min,
                    value: rangeValue.value,
                    dateList: rangeValue.dateList,
                    setValue: setRangeValue,
                  }}
                  format={monthlyDaily ? "DD MMM YY" : "MMM YY"}
                />
              </div>
            </Card>
          )}
        </div>

        <div className={styles.detail_wrapper} data-active={!trendsAndDetail}>
          <Table
            data={data.data}
            columns={data.columns}
            defaultSelectedRows={defaultSelectedHolidays}
            selectableRows
            pagination
            paginationModel={meta}
            defaultPageSize={10}
            loading={loading}
            serverMode="server"
            tableHeight="570px"
            onRowSelect={(newSelectedRows) => {
              setSelectedHolidays(newSelectedRows.map((row) => row.id));
            }}
            onColumnOrderChange={(newColumnOrder) => {
              console.log(
                "Yeni Kolon Sıralaması:",
                newColumnOrder.map((col) => col.key)
              );
            }}
            onPaginationModelChange={(newPaginationModel) => {
              // fetch data from server
              setPaginationModel(newPaginationModel);
            }}
            onSortModelChange={(newSortModel) => {
              // fetch data from server
              setSortModel(newSortModel);
            }}
            onFilterModelChange={(newFilterModel) => {
              // fetch data from server
            }}
          />

          <div className={styles.range_wrapper}>
            <RangeSlider
              data={{
                max: rangeValue.max,
                min: rangeValue.min,
                value: rangeValue.value,
                dateList: rangeValue.dateList,
                setValue: setRangeValue,
              }}
              format={monthlyDaily ? "DD MMM YY" : "MMM YY"}
            />
          </div>
        </div>
      </div>
    </Card>
  );
}
