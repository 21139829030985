import { IFilterContext } from "types/types";
import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";
import { RestClient } from "../../RestClient";

interface IFareRevenueClient {
  filterList: IFilterContext;
  graphCarriers: string;
  time_direction: string;
  date_range_start: string;
  date_range_end: string;
  agg_type: string;
  dark_theme: boolean;
}

export class FareRevenueClient {
  private static _client: RestClient;

  static fetchfareRevenueFareTrends({
    filterList,
    graphCarriers,
    time_direction,
    date_range_start,
    date_range_end,
    agg_type,
    dark_theme,
  }: IFareRevenueClient) {
    const qp = QueryParameterStringBuilder.apply(filterList);
    return FareRevenueClient.getClient().getCall(
      `/api/msdv2/bookings/fare-trends?time_direction=${time_direction}&graph_carriers=${graphCarriers}&date_range_start=${date_range_start}&date_range_end=${date_range_end}&agg_type=${agg_type}&${qp}&dark_theme=${dark_theme}`
    );
  }

  static getClient() {
    if (FareRevenueClient._client == null) {
      FareRevenueClient._client = new RestClient();
    }
    return FareRevenueClient._client;
  }
}
