import React, { forwardRef } from "react";
import { MdCancel } from "react-icons/md";
import { TableColumn } from "../type";
import styles from "./styles.module.css";
import { ConfigProvider, Select } from "antd";

interface MultiFilterProps {
  allColumns: TableColumn[];
  column: TableColumn;
  filterModel: {
    filterKey: string;
    filterValues: string[] | number[];
  }[];
  setFilterModel: React.Dispatch<
    React.SetStateAction<
      {
        filterKey: string;
        filterValues: string[] | number[];
      }[]
    >
  >;
  handleMultiFilter: () => void;
  handleClose: () => void;
}

const MultiFilter = forwardRef<HTMLDivElement, MultiFilterProps>(
  (props, ref) => {
    const {
      allColumns,
      column,
      filterModel,
      setFilterModel,
      handleMultiFilter,
      handleClose,
      ...rest
    } = props;

    const handleFilterKeyOnChange = (e, sm, i) => {
      let newFilterModel = filterModel;
      newFilterModel = newFilterModel.map((item, index) => {
        if (item.filterKey === sm.filterKey && i === index) {
          return { filterKey: e.target.value, filterValues: [] };
        }
        return item;
      });
      setFilterModel(newFilterModel);
    };

    const handleFilterValueOnChange = (e, sm, i) => {
      let newFilterModel = filterModel;
      newFilterModel = newFilterModel.map((item, index) => {
        if (item.filterKey === sm.filterKey && i === index) {
          return { ...item, filterValues: e };
        }
        return item;
      });
      setFilterModel(newFilterModel);
    };

    const handleRemoveFilterModel = (i) => {
      if (filterModel.length > 0) {
        let newFilterModel = filterModel.filter((_, index) => i !== index);
        setFilterModel(newFilterModel);
      } else {
        handleClose();
      }
    };

    return (
      <div className={styles.container} ref={ref} {...rest}>
        <h4>Filter Menu</h4>
        <div>
          {filterModel.map((sm, index) => {
            return (
              <div className={styles.content} key={index}>
                <MdCancel
                  size={16}
                  className={styles.filter_remove_icon}
                  onClick={() => handleRemoveFilterModel(index)}
                />
                <span>Column</span>
                <select
                  className={styles.select_col}
                  onChange={(e) => {
                    handleFilterKeyOnChange(e, sm, index);
                  }}
                  value={sm.filterKey}
                >
                  {allColumns.map((item, index) => (
                    <option key={index} value={item.key}>
                      {item.title}
                    </option>
                  ))}
                </select>
                <span>Value</span>
                <ConfigProvider
                  theme={{
                    components: {
                      Select: {
                        zIndexPopup: 9999,
                      },
                    },
                  }}
                >
                  {allColumns
                    .filter(
                      (filteredColumn) => filteredColumn.key === sm.filterKey
                    )
                    .some((someColumn) => Array.isArray(someColumn.options)) ? (
                    <Select
                      mode="multiple"
                      size="small"
                      allowClear
                      style={{ width: 150 }}
                      placeholder="Please select"
                      // defaultValue={sm.filterValues}
                      value={sm.filterValues}
                      onChange={(value) => {
                        handleFilterValueOnChange(value, sm, index);
                      }}
                      maxTagCount={"responsive"}
                      options={
                        allColumns.filter((col) => col.key === sm.filterKey)[0]
                          .options ?? []
                      }
                    />
                  ) : (
                    <Select
                      mode="tags"
                      size="small"
                      allowClear
                      style={{ width: 150 }}
                      placeholder="Please select"
                      // defaultValue={sm.filterValues}
                      value={sm.filterValues}
                      onChange={(value) => {
                        handleFilterValueOnChange(value, sm, index);
                      }}
                      maxTagCount={"responsive"}
                      notFoundContent={
                        "Insert with enter if there are no options"
                      }
                      options={[]}
                    />
                  )}
                </ConfigProvider>
                {/* <select
                  value={sm.filterValues[0]}
                  onChange={(e) => handleFilterValueOnChange(e, sm, index)}
                >
                  <option value="test">Test1</option>
                  <option value="test2">Test2</option>
                </select> */}
              </div>
            );
          })}
        </div>
        <div className={styles.buttons_wrapper}>
          <button
            style={{ marginTop: 8 }}
            onClick={() => {
              // Çoklu filtre eklerken, eklenmiş kolonları kontrol eder ve eklenmemiş olan kolonu sıralamaya ekler.
              let nextFilterItem = allColumns.find((col) =>
                filterModel.every((model) => col.key !== model.filterKey)
              );
              if (nextFilterItem) {
                setFilterModel([
                  ...filterModel,
                  { filterKey: nextFilterItem.key, filterValues: [] },
                ]);
              }
            }}
          >
            Add Filter
          </button>
          <button style={{ marginTop: 8 }} onClick={handleMultiFilter}>
            Apply
          </button>
        </div>
      </div>
    );
  }
);

export default MultiFilter;
